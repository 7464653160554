.contain {
  margin: auto auto;
  padding: 2em 2em;
}

.section-wrapper {
  margin: 0 auto;
  border: 1px solid #e6e6e6;
  margin: 0px;
  padding: 8px 16px 8px 16px;
	border-radius: 0.3rem;
  box-shadow: 0px 2px 4px #e6e6e6;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.shadow {
  box-shadow: 0px 1px 2px #e6e6e6;
}

.ant-table-thead > tr > th {
  background-color:#005AAB !important;
  color: rgb(255, 255, 255) !important;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: white;
  color: #2b8d18;
}

.leaflet-container {
  z-index: 0;
  height: 100%;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.table-row-red {
  background-color: rgb(248, 218, 215);
}

.table-row-red:hover > td {
  background: unset !important;
}

.main-content {
  justify-content: start;
  flex-direction: column;
  height: 100%;
  display: flex;
}

.main-content .button-bottom-page {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 16px;
}

.custom-inline-form-item .ant-form-item {
  margin-bottom: 8px;
}

.tabbar-bottom {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
  z-index:1 ;
}

.navbar-top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
  z-index:1 ;
  background-color: #EDEDED;
  position: fixed;
  width: 100%;
}

.float-button-mobile {
  margin-bottom: 35px;
}

.Carousel-container {
  z-index: 0;
  height: 100% !important;
  width: 100% !important;
}

.custom-card-body .ant-card-body {
  padding: 16px !important;
}